$cpink-400: #E7569A;
$cpink-300: #ff7aaf;
$cpink-200: #ed82b4;
$cpink-150: #FF8CC2;
$cpink-100: #FDF2F7;

$cblack-300: #21221C;
$cblack-200: #3C3E32;
$cblack-150: #4f4f4f;
$cblack-100: #696463;
$cblack-50: #918a88;
$cblack-10: #E0E0E0;

$cred: #C34837;
$cgreen: #6A966A;

$cyellow: #FFEC8F;
$cyellow-100 : #fee07c;
// $cyellow-100 : #FDDC61;
$cyellow-200: #F3BA12;
$cyellow-300: #D0924C;

$cbeige: #FFF7F1;
$cbeige-secondary: #FDF1E7;


$ccoral-red: #FBB0AA;
$cwarm-yellow: #F8D47A;
$ccold-blue: #ABC8FF;
$cmint-green: #7FD1AE;
$cgum-pink: #F5B4EB;
$csoft-purple: #CBB4F0;
$csoft-green: #94D0A2;
$clight-pink: #FFBBD6;
$cpeach-orange: #FAB890;

// $cdeep-green: #94C38D;

$cbright-pink: #FFA4CE;
$cbright-yellow: #FFD363;
$csky-blue: #89B2FF;
$clight-blue: #DAE6FE;

$clevel-1: #70AA7A;
$clevel-2: #89B2FF;
$clevel-3: #FAB890;



$crole-student: $cbright-yellow;
$crole-teacher: $ccold-blue;
$crole-parent: $cmint-green;
$crole-graduate: $cbright-pink;
$crole-partner: $ccoral-red;


$color-variants: "blue"rgba($clight-blue, 0.6) $clight-blue rgba(255, 255, 255, 0),
"green"rgba(207, 234, 204, 0.7) #bee1b7 rgba(255, 255, 255, 0),

// "D1"rgba(216, 107, 68, 0.5) rgba(216, 107, 68, 0.6) #D86C44,
// "D2"rgba(248, 217, 124, 0.5) rgba(248, 217, 124, 0.6) #F8D97C,
// "D3"rgba(129, 195, 217, 0.5) rgba(129, 195, 217, 0.6) #81C3D9,
// "D4"rgba(109, 172, 116, 0.5) rgba(109, 172, 116, 0.6) #6DAC74,
// "D5"rgba(240, 151, 193, 0.5) rgba(240, 151, 193, 0.6) #F097C1,
// "D6"rgba(163, 167, 219, 0.5) rgba(163, 167, 219, 0.6) #A3A7DB,
// "D7"rgba(106, 171, 96, 0.5) rgba(106, 171, 96, 0.6) #6AAB60,
// "D8"rgba(232, 169, 89, 0.5) rgba(232, 169, 89, 0.6) #E8A959,
// "D9"rgba(136, 164, 236, 0.5) rgba(136, 164, 236, 0.6) #88A4EC;
"D1"rgba($ccoral-red, 0.5) rgba($ccoral-red, 0.6) $ccoral-red,
"D2"rgba($cwarm-yellow, 0.5) rgba($cwarm-yellow, 0.6) $cwarm-yellow,
"D3"rgba($ccold-blue, 0.5) rgba($ccold-blue, 0.6) $ccold-blue,
"D4"rgba($cmint-green, 0.5) rgba($cmint-green, 0.6) $cmint-green,
"D5"rgba($cgum-pink, 0.5) rgba($cgum-pink, 0.6) $cgum-pink,
"D6"rgba($csoft-purple, 0.5) rgba($csoft-purple, 0.6) $csoft-purple,
"D7"rgba($csoft-green, 0.5) rgba($csoft-green, 0.6) $csoft-green,
"D8"rgba($cpeach-orange, 0.5) rgba($cpeach-orange, 0.6) $cpeach-orange,
"D9"rgba($csky-blue, 0.5) rgba($csky-blue, 0.6) $csky-blue;

$c: rgba(251, 176, 170, 1);
$c: rgba(248, 212, 122, 1);
$c: rgba(171, 200, 255, 1);
$c: rgba(127, 209, 174, 1);
$c: rgba(245, 180, 235, 1);
$c: rgba(203, 180, 240, 1);
$c: rgba(148, 208, 162, 1);
$c: rgba(250, 184, 144, 1);
$c: rgba(138, 179, 255, 1);