@font-face {
  font-family: PN;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/proximanova_regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: PN;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.eot") format("embedded-opentype");
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.ttf") format("truetype"), url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: PN;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/proximanova_bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: PNeb;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.eot") format("embedded-opentype");
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.ttf") format("truetype"), url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: DrukWide;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/Druk Text Wide Cyr Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: DrukWide;
  src: url("https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/Druk%20Text%20Wide-Super-Desktop.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

.rf-H1 {
  color: PN, Arial, sans-serif;
  font-family: DrukWide, Montserrat, Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
}

.rf-H2 {
  color: PN, Arial, sans-serif;
  font-family: DrukWide, Montserrat, Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
}

.rf-H3 {
  color: PN, Arial, sans-serif;
  font-family: DrukWide, Montserrat, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
}

.rf-H4 {
  color: PN, Arial, sans-serif;
  font-family: DrukWide, Montserrat, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
}

.rf-subheader {
  color: #4f4f4f;
  font-family: PN, Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
}

.rf-subheader_size_small {
  font-size: 18px;
}

.rf-label {
  color: PN, Arial, sans-serif;
  font-family: PN, Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.rf-hint {
  color: #4f4f4f;
  font-family: PN, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.rf-text {
  font-family: PN, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.rf-link {
  color: #e7569a;
  font-family: PN, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (max-width: 480px) {
  .rf-H1 {
    font-size: 28px;
    line-height: 110%;
  }

  .rf-H2 {
    font-size: 24px;
    line-height: 110%;
  }

  .rf-H3 {
    font-size: 20px;
    line-height: 110%;
  }

  .rf-H4, .rf-subheader {
    font-size: 17px;
    line-height: 110%;
  }

  .rf-hint {
    font-size: 14px;
    line-height: 110%;
  }

  .rf-text, .rf-link {
    font-size: 16px;
    line-height: 110%;
  }
}

.rf-share-container {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.align_center {
  text-align: center;
}

.align_left {
  text-align: left;
}

.rf-button {
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 30px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 15px 30px;
  transition: background .3s ease-in-out;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 480px) {
  .rf-button {
    padding: 12px 24px;
  }
}

.rf-button_type_primary.rf-button_color_pink {
  background: #ff7aaf;
  border: 2px solid #ff4790;
}

.rf-button_type_primary.rf-button_color_pink .rf-button__label {
  color: #fdf2f7;
}

.rf-button_type_primary.rf-button_color_pink:hover {
  background-color: #ff4790;
}

.rf-button_type_secondary.rf-button_color_pink {
  background: #fdf2f7;
  border: 2px solid #ff7aaf;
}

.rf-button_type_secondary.rf-button_color_pink .rf-button__label {
  color: #ff7aaf;
}

.rf-button_type_secondary.rf-button_color_pink:hover {
  background-color: #f6c6dc;
}

.rf-button_type_primary.rf-button_color_yellow {
  background: #f3ba12;
  border: 2px solid #d0924c;
}

.rf-button_type_primary.rf-button_color_yellow .rf-button__label {
  color: #fff7f1;
}

.rf-button_type_primary.rf-button_color_yellow:hover {
  background-color: #c8980a;
}

.rf-button_type_secondary.rf-button_color_yellow {
  background: #f3ba12;
  border: 2px solid #d0924c;
}

.rf-button_type_secondary.rf-button_color_yellow .rf-button__label {
  color: #d0924c;
}

.rf-button_type_secondary.rf-button_color_yellow:hover {
  background-color: #c8980a;
}

.rf-button_type_secondary.rf-button_color_yellow {
  background: #f3ba12;
  border: 2px solid #d0924c;
}

.rf-button_type_secondary.rf-button_color_yellow .rf-button__label {
  color: #d0924c;
}

.rf-button_type_secondary.rf-button_color_yellow:hover {
  background-color: #c8980a;
}

#allrecords .ya-share2 ul {
  padding-left: 0;
}

.v3_128 {
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.v94_26 {
  margin-bottom: 30px;
}

.v2_8 {
  word-break: break-word;
  margin-bottom: 5px;
}

.v94_25 {
  word-break: break-word;
}

.v3_57 {
  color: #3c3e32;
  text-align: left;
  word-break: break-word;
}

.t390 .t-popup__container {
  border-radius: 25px;
}

.t-records .ya-share2 .ya-share2__list {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.ad088494.css.map */
