@import './scss/base.scss';

#allrecords .ya-share2 ul {
	padding-left: 0;
}

// header block
.v3_128 {
	margin-left: $base * 3;
	margin-right: $base * 3;
	margin-bottom: $base * 6;
}

// header+supheader block
.v94_26 {
	margin-bottom: $base * 6;
}

// sup header Опрос сообщества Рыбаков Фонд, г. Москва
.v2_8 {
	margin-bottom: $base;
	word-break: break-word;
}

// header  Участвуйте в развитии школы
.v94_25 {
	word-break: break-word;
}

// subheader  Пройдите 10 минутный опрос и узнайте, как эта школа может развиваться для вашего благополучия
.v3_57 {
	color: #3C3E32;
	text-align: left;
	word-break: break-word;
}

.t390 .t-popup__container {
	border-radius: 25px;
}

.t-records .ya-share2 .ya-share2__list {
	margin-bottom: 0;
}