@font-face {
	font-family: 'PN';
	// src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.eot') format('embedded-opentype');
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/proximanova_regular.ttf') format('truetype');

	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'PN';
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.eot') format('embedded-opentype');
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.ttf') format('truetype'),
		url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'PN';
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/proximanova_bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'PNeb';
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.eot') format('embedded-opentype');
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.ttf') format('truetype'),
		url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Extrabld.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'DrukWide';
	// src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.eot') format('embedded-opentype');
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/Druk Text Wide Cyr Heavy.otf') format('opentype');

	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'DrukWide';
	// src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/ProximaNova-Semibold.eot') format('embedded-opentype');
	src: url('https://storage.yandexcloud.net/rf-diagnosticstool-static/fonts/Druk%20Text%20Wide-Super-Desktop.otf') format('opentype');

	font-weight: 900;
	font-style: normal;
}