@use "sass:math";
@import './font-faces.scss';
@import './colors.scss';

$h-font-family: 'DrukWide',
Montserrat,
Arial,
sans-serif;

$subheader-font-family: PN,
Arial,
sans-serif;

$text-font-family: PN,
Arial,
sans-serif;

$base: 5px;
$base-line-height : 120%;
$base-line-height-mobile: 110%;
$clink: $cpink-400;

$ch-black: $cblack-300;
$csubh-black: $cblack-150;

$breakpoint-mobile: 480px;
$breakpoint-tablet: 960px;

.rf {

	&-H1 {
		font-family: $h-font-family;
		font-style: normal;
		font-weight: 900;
		font-size: 40px;
		line-height: 100%;
		color: $text-font-family;
	}

	&-H2 {
		font-family: $h-font-family;
		font-style: normal;
		font-weight: 900;
		font-size: 32px;
		line-height: 100%;
		color: $text-font-family;
	}

	&-H3 {
		font-family: $h-font-family;
		font-style: normal;
		font-weight: 900;
		font-size: 24px;
		line-height: $base-line-height;
		color: $text-font-family;
	}

	&-H4 {
		font-family: $h-font-family;
		font-style: normal;
		font-weight: 900;
		font-size: 18px;
		line-height: $base-line-height;
		color: $text-font-family;
	}

	&-subheader {
		font-family: $subheader-font-family;
		font-weight: 500;
		font-size: 22px;
		line-height: $base-line-height;
		color: $csubh-black;

		&_size {
			&_small {
				font-size: 18px;
			}
		}
	}

	&-label {
		font-family: $text-font-family;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 23px;
		color: $text-font-family;
	}

	&-hint {
		font-family: $text-font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: $base-line-height;
		color: $csubh-black;
	}

	&-text {
		font-family: $text-font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: $base-line-height;
	}

	&-link {
		font-family: $text-font-family;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: $base-line-height;
		color: $clink;
	}

	@media screen and (max-width: $breakpoint-mobile) {
		&-H1 {
			font-size: 28px;
			line-height: $base-line-height-mobile;
		}

		&-H2 {
			font-size: 24px;
			line-height: $base-line-height-mobile;
		}

		&-H3 {
			font-size: 20px;
			line-height: $base-line-height-mobile;
		}

		&-H4 {
			font-size: 17px;
			line-height: $base-line-height-mobile;
		}

		&-subheader {
			font-size: 17px;
			line-height: $base-line-height-mobile;
		}

		&-hint {
			font-size: 14px;
			line-height: $base-line-height-mobile;
		}

		&-text {
			font-size: 16px;
			line-height: $base-line-height-mobile;
		}

		&-link {
			font-size: 16px;
			line-height: $base-line-height-mobile;
		}
	}

	&-share-container {
		display: flex;
		justify-content: center;
	}
}

.align {
	&_center {
		text-align: center;
	}
	&_left {
		text-align: left;
	}
}

$cpink-main: $cpink-300;
$cpink-secondary: $cpink-100;

.rf-button {
	$this: &;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	$vpadding: 3*$base;
	$hpadding: $vpadding * 2;
	padding: $vpadding $hpadding;

	box-sizing: border-box;
	border-radius: 30px;

	cursor: pointer;

	transition: background .3s ease-in-out;

	@media screen and (max-width: $breakpoint-mobile) {
		$vpadding: math.div(3 * $base, 5) * 4;
		$hpadding: $vpadding * 2;
		padding: $vpadding $hpadding;
	}

	@mixin rf-button-type($type, $colorName, $text, $background, $border) {
		$me: &;

		&_type {

			// primary | secondary
			&_#{$type} {

				$colored: #{$me}_color;

				&#{$colored}_#{$colorName} {

					#{$me}__label {
						color: $text;
					}

					background: $background;
					border: 2px solid $border;

					&:hover {
						background-color: darken($color: $background, $amount: 10);
					}
				}
			}
		}
	}

	@include rf-button-type(primary, 'pink', $cpink-secondary, $cpink-main, darken($color: $cpink-main, $amount: 10));
	@include rf-button-type(secondary, 'pink', $cpink-main, $cpink-secondary, $cpink-main);

	@include rf-button-type(primary, 'yellow', $cbeige, $cyellow-200, $cyellow-300);
	@include rf-button-type(secondary, 'yellow', $cyellow-300, $cyellow-200, $cyellow-300);

	&_type {

		&_secondary {

			$colored: #{$this}_color;

			// &#{$colored}_pink {

			// 	#{$this}__label {
			// 		color: $cpink-main;
			// 	}

			// 	background: $cpink-secondary;
			// 	border: 2px solid $cpink-main;

			// 	&:hover {
			// 		background-color: darken($color: $cpink-secondary, $amount: 10);
			// 	}
			// }

			&#{$colored}_yellow {

				#{$this}__label {
					color: $cyellow-300;
				}

				background: $cyellow-200;
				border: 2px solid $cyellow-300;

				&:hover {
					background-color: darken($color: $cyellow-200, $amount: 10);
				}
			}
		}
	}

}

@mixin flex-row() {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}